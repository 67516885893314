import { useState, useCallback, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducer'
import { HIDE } from 'reducer/mail'
import { SEND_MAIL } from 'gql/mail'
import { SHOW as SNACKBARSHOW } from 'reducer/snackbar'
import { Button, Tabs, Tab } from '@mui/material'
import DialogComponent from 'styled/Dialog'
import SendComponent from './Send'
import PayPaperComponent from './PayPaper'

export interface State {
    from: string;
    to: string;
    subject: string;
    body: string;
    file1: File | null;
    file2: File | null;
    file3: File | null;
    lang: string;
    bankbook: boolean;
    introduce: boolean;
    register: boolean;
    seal: boolean;
    isHtml: boolean;
}

export type OnChange = (name:string, value:string | boolean | null) => void;

const initState = {
    from: 'support',
    to: '',
    subject: '안녕하세요 리스토베이입니다. 요청하신 견적서 보내드립니다.',
    body: '안녕하세요 리스토베이입니다.\n요청하신 견적서 파일을 첨부 하였습니다.\n\n감사합니다.\n리스토베이 드림',
    file1: null,
    file2: null,
    file3: null,
    lang: 'ko',
    bankbook: false,
    introduce: false,
    register: false,
    seal: false,
    isHtml: false
}


function MailComponent() {
    const dispatch = useDispatch()

    const mail = useSelector((state:RootState) => state.mail)

    const { to, show } = mail

    const [ tab, setTab ] = useState(0)
    const [ state, setState ] = useState<State>({
        ...initState,
        to
    })

    const [ sendMail ] = useMutation(SEND_MAIL, {
        onCompleted: () => {
            dispatch({ type: SNACKBARSHOW, message: '성공햇다요' })
        },
        onError: (e) => {
            const { message } = e
            dispatch({ type: SNACKBARSHOW, message })
        }
    })

    function handleChangeTab(event: React.SyntheticEvent, newValue: number) {
        setTab(newValue);
    }

    const handleChange:OnChange = useCallback((name, value) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }, [])

    const handleClose = useCallback(() => {
        dispatch({ type: HIDE })
    }, [dispatch])

    

    const handleSave = useCallback(() => {
        const files = []
        if (state.file1) files.push(state.file1)
        if (state.file2) files.push(state.file2)
        if (state.file3) files.push(state.file3)

        const input = {
            from: state.from,
            to: state.to,
            subject: state.subject,
            body: state.body.replace(/\n/g, '<br />'),
            files,
            lang: state.lang,
            bankbook: state.bankbook,
            introduce: state.introduce,
            register: state.register,
            seal: state.seal,
            isHtml: state.isHtml
        }

        sendMail({
            variables:{
                input
            } 
        })
    }, [state, sendMail])

    const Buttons = useCallback(() => {
        const disabled = !state.to || !state.subject || !state.body
        return (
            <>
            <Button disabled={disabled} onClick={handleSave}>발송</Button>
            <Button color="inherit" onClick={handleClose}>닫기</Button>
            </>
        )
    }, [state.to, state.subject, state.body, handleSave, handleClose])

    useEffect(() => {
        if (show) {
            setState({
                ...initState,
                to
            })

        }       
    }, [to, show])
    
    return (
        <DialogComponent maxWidth="lg" title="메일 발송" Buttons={Buttons} open={show} onClose={handleClose}>

            <Tabs value={tab} onChange={handleChangeTab} sx={{marginBottom: 3}}>
                <Tab label="메일"/>
                <Tab label="견적서"/>
            </Tabs>

            <div style={{display: tab === 0 ? 'block' : 'none'}}>
                <SendComponent state={state} onChange={handleChange}/>
            </div>

            <div style={{display: tab === 1 ? 'block' : 'none'}}>
                <PayPaperComponent/>
            </div>
           
        </DialogComponent>
    )
}

export default MailComponent